import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService, KYBService } from '../../../_services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { config } from '../../../../assets/configuration';
import * as FileSaver from 'file-saver';
import { formatDate } from '@angular/common';
import { Chart } from 'angular-highcharts';
import * as svg from 'save-svg-as-png';
import * as htmlToImage from 'html-to-image';
var EntityReportComponent = /** @class */ (function () {
    function EntityReportComponent(router, callsService, alertService, kybService, authenticationService, modalService) {
        this.router = router;
        this.callsService = callsService;
        this.alertService = alertService;
        this.kybService = kybService;
        this.authenticationService = authenticationService;
        this.modalService = modalService;
        this.allEntitiesResponse = {};
        this.entities = [];
        this.entitiesLoading = false;
        this.entityResponse = {};
        this.rolesResponse = {};
        this.documentContentResponse = {};
        this.basicInformation = {};
        this.pdfResponse = {};
        this.loading2 = false;
        this.eventsForEntityResponse = {};
        this.reportLogo = config.reportLogo;
        this.entityId = '';
        this.loadingImg = config.loadingImg;
        this.timestampFrom = null;
        this.timestampTo = null;
        this.tags = [];
        this.entityName = '';
        this.relationResponse = {};
        this.controlResponse = {};
        this.UBOResponse = {};
        this.exclamationImage = '../../../assets/images/exclamation.png';
        this.emptyUBO = false;
        this.emptyControl = false;
        this.relationshipGraphData = [];
        this.newChilds = [];
        this.nodes = [];
        this.controls = [];
        this.controlData = [];
        this.controlNodes = [];
        this.owners = [];
        this.uboData = [];
        this.uboNodes = [];
        this.entityLevel = 0;
        this.titles = [];
        this.uboLevels = [];
        this.uboChartEncodedImage = '';
        this.counter = 0;
    }
    EntityReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.testFile = '../../../../assets/images/exclamation.png';
        this.timestampTo = new Date();
        this.timestampFrom = new Date();
        this.timestampFrom.setDate(this.timestampFrom.getDate() - 7);
        this.timestampFrom = this.timestampFrom.toISOString();
        this.timestampTo = this.timestampTo.toISOString();
        this.kybService.getAllEntities({}).subscribe(function (data) {
            _this.allEntitiesResponse = data;
            _this.entities = _this.allEntitiesResponse.data;
            _this.entitiesLoading = false;
        }, function (error) { });
    };
    EntityReportComponent.prototype.blobToFile = function (data, type, fileName) {
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        var blob = new Blob([data], { type: type });
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
    EntityReportComponent.prototype.prettyTimestamp = function (timestamp) {
        return formatDate(timestamp, 'dd/MM/yyyy, h:mm:ss a z', 'en');
    };
    EntityReportComponent.prototype.clientDossierForEntity = function (entityId) {
        var _this = this;
        this.kybService.downloadClientDossier(entityId).subscribe(function (data) {
            if (data) {
                _this.blobToFile(data, 'text/xml', 'KYC3_Client_Dossier.zip');
            }
        });
    };
    EntityReportComponent.prototype.reportForEntity = function (entityId) {
        this.entityId = entityId;
        for (var i = 0; i < this.entities.length; i++) {
            if (this.entities[i].id === this.entityId) {
                this.entityName = this.entities[i].name;
                this.entityType = this.entities[i].entityType;
            }
        }
        this.tags = [];
        this.loading2 = true;
        this.getGraphs();
    };
    EntityReportComponent.prototype.getGraphs = function () {
        var _this = this;
        this.relationResponse = {};
        this.kybService.getRelationships(this.entityId).subscribe(function (data) {
            _this.relationResponse = data;
            if (_this.relationResponse.data.roles.length === 0) {
                _this.relationshipGraphString = null;
                _this.export();
            }
            else {
                _this.drawRelationshipGraph(_this.relationResponse.data.roles);
            }
        }, function (error) { });
        this.kybService.getUBOGraph(this.entityId, 'control').subscribe(function (data) {
            _this.controlResponse = data;
            if (_this.controlResponse.data.controls.length === 0 && _this.controlResponse.data.owners.length === 0) {
                _this.controlChartString = null;
            }
            else {
                _this.drawControlChart(_this.controlResponse.data);
            }
        }, function (error) { });
        this.kybService.getUBOGraph(this.entityId, 'owner').subscribe(function (data) {
            _this.UBOResponse = data;
            if (_this.UBOResponse.data.owners.length === 0 && _this.UBOResponse.data.controls.length === 0) {
                _this.nodes = [];
                _this.uboChartString = null;
            }
            else {
                _this.drawUBOChart(_this.UBOResponse.data);
                _this.drawNewUBOChart(_this.UBOResponse.data);
            }
        }, function (error) { });
    };
    EntityReportComponent.prototype.drawRelationshipGraph = function (relationship) {
        this.relationshipGraphData = [];
        if (relationship.length === 0) {
            this.relationshipGraphData.push({ 'from': this.entityName, 'to': this.entityName });
        }
        else {
            for (var i = 0; i < relationship.length; i++) {
                var currentColorFrom = '';
                var currentColorTo = '';
                var currentStatusFrom = '';
                var currentStatusTo = '';
                if (relationship[i].entity1.customOverallRisk.riskStatus !== null) {
                    currentStatusFrom = relationship[i].entity1.customOverallRisk.riskStatus;
                }
                else {
                    currentStatusFrom = relationship[i].entity1.combinedOverallRisk.riskStatus;
                }
                if (relationship[i].entity2.customOverallRisk.riskStatus !== null) {
                    currentStatusTo = relationship[i].entity2.customOverallRisk.riskStatus;
                }
                else {
                    currentStatusTo = relationship[i].entity2.combinedOverallRisk.riskStatus;
                }
                switch (currentStatusFrom) {
                    case 'HIGH':
                        currentColorFrom = 'rgb(214, 134, 15)';
                        break;
                    case 'LOW':
                        currentColorFrom = '#72C245';
                        break;
                    case 'MEDIUM':
                        currentColorFrom = '#FDD32B';
                        break;
                    case 'REJECTED':
                        currentColorFrom = '#D60000';
                        break;
                    default:
                        currentColorFrom = '#bdbdbd';
                }
                switch (currentStatusTo) {
                    case 'HIGH':
                        currentColorTo = 'rgb(214, 134, 15)';
                        break;
                    case 'LOW':
                        currentColorTo = '#72C245';
                        break;
                    case 'MEDIUM':
                        currentColorTo = '#FDD32B';
                        break;
                    case 'REJECTED':
                        currentColorTo = '#D60000';
                        break;
                    default:
                        currentColorTo = '#bdbdbd';
                }
                // tslint:disable-next-line:max-line-length
                this.relationshipGraphData.push({ 'from': relationship[i].entity1.entityName, 'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName, 'colorFrom': currentColorFrom, 'colorTo': currentColorTo });
            }
        }
        this.chart = new Chart({
            chart: {
                type: 'networkgraph',
                events: {
                    load: function () {
                        var series = this.series[0];
                        series.data.forEach(function (point, i) {
                            if (point.colorFrom && series.nodes[i]) {
                                series.nodes[i].color = point.colorFrom;
                            }
                            series.nodes[i].name = point.from + ' (' + point.name + ' of ' + point.to + ')';
                            if (point.colorTo && series.nodes[i + 1]) {
                                series.nodes[i + 1].color = point.colorTo;
                            }
                        });
                    }
                },
                height: '100%'
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                networkgraph: {
                    marker: {
                        radius: 20
                    },
                    keys: ['from', 'to'],
                    layoutAlgorithm: {
                        enableSimulation: false,
                        linkLength: 40,
                        friction: -0.9
                    },
                    lineWidth: 4
                }
            },
            series: [{
                    type: 'networkgraph',
                    dataLabels: {
                        enabled: true,
                        linkTextPath: {
                            attributes: {
                                dy: 12
                            }
                        },
                        linkFormat: 'is {point.name} of'
                    },
                    id: 'lang-tree',
                    color: '#68799c',
                    data: this.relationshipGraphData
                }],
            exporting: {
                enabled: false
            }
        });
        var parentThis = this;
        setTimeout(function () {
            parentThis.export();
        }, 3000);
    };
    EntityReportComponent.prototype.prepareControlData = function (startEntity, entities) {
        for (var i = 0; i < entities.length; i++) {
            this.controls.push({ from: startEntity, to: entities[i].entityName });
            this.controlNodes.push({ id: entities[i].entityName, title: entities[i].entityName,
                name: 'E' + (entities[i].distance + 1) + '-' + entities[i].roleName + '-' +
                    entities[i].entityType, detailId: entities[i].id });
            if (entities[i].entities.length > 0) {
                this.prepareControlData(entities[i].entityName, entities[i].entities);
            }
        }
        return this.controls;
    };
    EntityReportComponent.prototype.drawControlChart = function (controlData) {
        if (this.controlChart !== undefined) {
            this.controlChart.destroy();
        }
        if (controlData.controls.length === 0) {
            this.emptyControl = true;
            this.controlData = [];
            this.controlNodes = [];
            this.controls = [];
        }
        else {
            this.emptyControl = false;
            this.controlData = [];
            this.controlNodes = [];
            this.controls = [];
            this.controlData = this.prepareControlData(controlData.entity.entityName, controlData.controls);
            this.controlNodes.push({ id: controlData.entity.entityName, title: controlData.entity.entityName,
                name: 'FOCUS Company', detailId: controlData.entity.id });
        }
        this.controlChartString = JSON.stringify({
            chart: {
                inverted: true,
                style: {
                    cursor: 'pointer'
                }
            },
            title: {
                text: ''
            },
            series: [{
                    type: 'organization',
                    name: controlData.entity.entityName,
                    keys: ['from', 'to'],
                    data: this.controlData,
                    levels: [{
                            level: 0,
                            color: 'silver',
                            dataLabels: {
                                color: 'white',
                            },
                        }, {
                            level: 1,
                            color: 'green',
                            dataLabels: {
                                color: 'white'
                            },
                        }, {
                            level: 2,
                            color: 'green',
                            dataLabels: {
                                color: 'white'
                            }
                        }, {
                            level: 3,
                            color: 'green',
                            dataLabels: {
                                color: 'white'
                            }
                        }, {
                            level: 4,
                            color: 'green',
                            dataLabels: {
                                color: 'white'
                            }
                        }],
                    nodes: this.controlNodes,
                    events: {},
                    colorByPoint: false,
                    color: '#007ad0',
                    dataLabels: {
                        color: 'white'
                    },
                    borderColor: 'white',
                    nodeWidth: 65
                }],
            exporting: {
                enabled: true,
                allowHTML: true,
                sourceWidth: 800
            },
            credits: {
                enabled: false
            },
        });
    };
    EntityReportComponent.prototype.prepareUBOData = function (startEntity, entities) {
        this.entityLevel++;
        var overallRisk = '';
        for (var i = 0; i < entities.length; i++) {
            var cyclesWith = '';
            this.owners.push({ from: entities[i].entityName, to: startEntity });
            if (entities[i].customOverallRisk.riskStatus !== null && entities[i].customOverallRisk.riskStatus !== null) {
                overallRisk = entities[i].customOverallRisk.riskStatus;
            }
            else {
                overallRisk = entities[i].combinedOverallRisk.riskStatus;
            }
            if (entities[i].cycles.length > 0) {
                for (var j = 0; j < entities[i].cycles.length; j++) {
                    cyclesWith += entities[i].cycles[j].name + ',';
                }
            }
            if (cyclesWith !== '') {
                for (var z = 0; z < this.uboNodes.length; z++) {
                    if (this.uboNodes[z].title === entities[i].entityName) {
                        this.uboNodes[z].color = '#cc0000';
                        this.uboNodes[z].cycles = cyclesWith.replace(/,([^,]*)$/, '$1');
                        this.uboNodes[z].image = this.exclamationImage;
                    }
                }
                if (this.titles.indexOf(entities[i].entityName) === -1) {
                    this.titles.push(entities[i].entityName);
                    this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, overallRisk: overallRisk, name: 'E' +
                            (entities[i].distance + 1) + '-' + entities[i].roleName, type: entities[i].entityType, detailId: entities[i].id, color: '#cc0000', cycles: cyclesWith.replace(/,([^,]*)$/, '$1'),
                        image: this.exclamationImage, layout: 'hanging' });
                }
            }
            else {
                if (this.titles.indexOf(entities[i].entityName) === -1) {
                    this.titles.push(entities[i].entityName);
                    if (entities[i].distance > 1) {
                        this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, overallRisk: overallRisk, name: 'E' +
                                (entities[i].distance + 1) + '-' + entities[i].roleName, type: entities[i].entityType, detailId: entities[i].id, cycles: '', layout: 'hanging' });
                    }
                    else {
                        this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, overallRisk: overallRisk, name: 'E' +
                                (entities[i].distance + 1) + '-' + entities[i].roleName, type: entities[i].entityType, detailId: entities[i].id, cycles: '' });
                    }
                }
            }
            if (entities[i].entities.length > 0) {
                this.prepareUBOData(entities[i].entityName, entities[i].entities);
            }
        }
        return this.owners;
    };
    EntityReportComponent.prototype.prepareNewUBOData = function (entity) {
        var _this = this;
        var childIds = [];
        if (entity.length > 0) {
            entity.forEach(function (element) {
                _this.prepareNewUBOData(element);
            });
        }
        else if (entity.entities && entity.entities.length > 0) {
            this.prepareNewUBOData(entity.entities);
            var _loop_1 = function (i) {
                if (entity.entities[i].entities.length > 0) {
                    entity.entities[i].entities.forEach(function (element) {
                        childIds.push({ childs: [{ id: element.id }], id: entity.entities[i].id });
                    });
                }
                else {
                    childIds.push({ id: entity.entities[i].id });
                }
                this_1.newChilds.push({ childs: childIds, id: entity.id });
            };
            var this_1 = this;
            for (var i = 0; i < entity.entities.length; i++) {
                _loop_1(i);
            }
        }
        else {
            this.newChilds.push({ id: entity.id });
        }
    };
    EntityReportComponent.prototype.putImageNameAndTitle = function (uboNode, child) {
        var _this = this;
        if (child.length > 0) {
            child.forEach(function (element) {
                _this.putImageNameAndTitle(uboNode, element);
            });
        }
        if (uboNode.detailId === child.id) {
            child.name = uboNode.name;
            child.title = uboNode.title;
            switch (uboNode.overallRisk) {
                case 'HIGH':
                    child.cssClass = 'OR-high';
                    break;
                case 'LOW':
                    child.cssClass = 'OR-low';
                    break;
                case 'MEDIUM':
                    child.cssClass = 'OR-medium';
                    break;
                case 'REJECTED':
                    child.cssClass = 'OR-rejected';
                    break;
                default:
                    child.cssClass = 'OR-unevaluated';
            }
            if (uboNode.type === 'PERSON') {
                child.image = '../../assets/images/person.svg';
            }
            else {
                child.image = '../../assets/images/building.svg';
            }
        }
        if (child.childs && child.childs.length > 0) {
            this.putImageNameAndTitle(uboNode, child.childs);
        }
        else if (uboNode.detailId === child.id) {
            child.name = uboNode.name;
            child.title = uboNode.title;
            switch (uboNode.overallRisk) {
                case 'HIGH':
                    child.cssClass = 'OR-high';
                    break;
                case 'LOW':
                    child.cssClass = 'OR-low';
                    break;
                case 'MEDIUM':
                    child.cssClass = 'OR-medium';
                    break;
                case 'REJECTED':
                    child.cssClass = 'OR-rejected';
                    break;
                default:
                    child.cssClass = 'OR-unevaluated';
            }
            if (uboNode.type === 'PERSON') {
                child.image = '../../assets/images/person.svg';
            }
            else {
                child.image = '../../assets/images/building.svg';
            }
        }
    };
    EntityReportComponent.prototype.drawNewUBOChart = function (UBOdata) {
        var _this = this;
        var currentChilds = [];
        var newNodes = [];
        UBOdata.owners.forEach(function (owner) {
            currentChilds = [];
            if (owner.entities.length > 0) {
                owner.entities.forEach(function (entity) {
                    _this.newChilds = [];
                    _this.prepareNewUBOData(entity);
                    currentChilds.push(_this.newChilds[_this.newChilds.length - 1]);
                });
            }
            else {
                currentChilds = [];
            }
            _this.nodes.push({ childs: currentChilds, id: owner.id });
        });
        this.uboNodes.forEach(function (uboNode) {
            _this.nodes.forEach(function (node) {
                if (uboNode.detailId === node.id) {
                    node.name = uboNode.name;
                    node.title = uboNode.title;
                    switch (uboNode.overallRisk) {
                        case 'HIGH':
                            node.cssClass = 'OR-high';
                            break;
                        case 'LOW':
                            node.cssClass = 'OR-low';
                            break;
                        case 'MEDIUM':
                            node.cssClass = 'OR-medium';
                            break;
                        case 'REJECTED':
                            node.cssClass = 'OR-rejected';
                            break;
                        default:
                            node.cssClass = 'OR-unevaluated';
                    }
                    if (uboNode.type === 'PERSON') {
                        node.image = '../../assets/images/person.svg';
                    }
                    else {
                        node.image = '../../assets/images/building.svg';
                    }
                }
                if (node.childs) {
                    _this.putImageNameAndTitle(uboNode, node.childs);
                }
            });
            if (uboNode.detailId === UBOdata.entity.id) {
                newNodes.push({ id: uboNode.detailId, name: uboNode.name, title: uboNode.title, childs: _this.nodes });
            }
        });
        this.nodes = newNodes;
    };
    EntityReportComponent.prototype.drawUBOChart = function (UBOdata) {
        this.titles = [];
        if (this.uboChart !== undefined) {
            this.uboChart.destroy();
        }
        if (UBOdata.owners.length === 0) {
            this.emptyUBO = true;
            this.uboData = [];
            this.uboNodes = [];
            this.uboLevels = [];
            this.entityLevel = 0;
            this.owners = [];
        }
        else {
            this.emptyUBO = false;
            this.uboData = [];
            this.uboNodes = [];
            this.uboLevels = [];
            this.entityLevel = 0;
            this.owners = [];
            this.uboData = this.prepareUBOData(UBOdata.entity.entityName, UBOdata.owners);
            // Check this for the colors
            for (var i = 0; i < this.entityLevel; i++) {
                this.uboLevels.push({ level: i, color: 'blue', dataLabels: { color: 'white' } });
            }
            this.uboNodes.push({ id: UBOdata.entity.entityName, title: UBOdata.entity.entityName,
                name: 'FOCUS Company', detailId: UBOdata.entity.id, color: 'silver' });
            var title = '';
            for (var x = 0; x < this.uboNodes.length; x++) {
                if (this.uboNodes[x].name === 'FOCUS Company') {
                    title = this.uboNodes[x].title;
                    this.index = x;
                }
            }
            for (var y = 0; y < this.uboNodes.length; y++) {
                if (this.uboNodes[y].title === title && this.uboNodes[y].name !== 'FOCUS Company') {
                    this.uboNodes[this.index].color = '#cc0000';
                    this.uboNodes[this.index].cycles = this.uboNodes[y].cycles;
                    this.uboNodes[this.index].image = this.uboNodes[y].image;
                    this.uboNodes.splice(y, 1);
                }
            }
            var cycleEntities = [];
            for (var p = 0; p < this.uboNodes.length; p++) {
                if (this.uboNodes[p].name === 'FOCUS Company') {
                    this.index = p;
                }
                if (this.uboNodes[p].cycles !== '') {
                    if (this.uboNodes[p].cycles !== undefined) {
                        cycleEntities = this.uboNodes[p].cycles.split(',');
                    }
                }
            }
            for (var q = 0; q < this.uboData.length; q++) {
                if (this.uboData[q].from === this.uboNodes[this.index].title && cycleEntities.indexOf(this.uboData[q].to) !== -1) {
                    this.uboData.splice(q, 1);
                }
            }
            this.uboChartString = JSON.stringify({
                chart: {
                    inverted: true
                },
                title: {
                    text: ''
                },
                series: [{
                        type: 'organization',
                        name: UBOdata.entity.entityName,
                        keys: ['from', 'to'],
                        data: this.uboData,
                        levels: this.uboLevels,
                        nodes: this.uboNodes,
                        events: {},
                        colorByPoint: false,
                        color: '#007ad0',
                        dataLabels: {
                            color: 'white'
                        },
                        borderColor: 'white'
                    }],
                exporting: {
                    enabled: true,
                    allowHTML: true,
                    sourceWidth: 800
                },
                credits: {
                    enabled: false
                },
            });
        }
    };
    EntityReportComponent.prototype.prepareOrganizationalChart = function (relationship, inputData) {
        if (relationship.length === 0) {
            inputData.push([this.entityName, this.entityName]);
        }
        else {
            this.counter += relationship.length;
            for (var i = 0; i < relationship.length; i++) {
                if (relationship[i].documentStatus === 'UNEVALUATED') {
                    inputData.push({ 'from': relationship[i].entity1.entityName, 'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName,
                        'color': '#bdbdbd' });
                }
                else if (relationship[i].documentStatus === 'ACCEPTED') {
                    inputData.push({ 'from': relationship[i].entity1.entityName,
                        'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName,
                        'color': '#72C245' });
                }
                else if (relationship[i].documentStatus === 'ESCALATED') {
                    inputData.push({ 'from': relationship[i].entity1.entityName,
                        'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName,
                        'color': '#FDD32B' });
                }
                else {
                    inputData.push({ 'from': relationship[i].entity1.entityName,
                        'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName, 'color': '#D60000' });
                }
                if (relationship[i].entity1.roles.length > 0) {
                    this.prepareOrganizationalChart(relationship[i].entity1.roles, inputData);
                }
                if (relationship[i].entity2.roles.length > 0) {
                    this.prepareOrganizationalChart(relationship[i].entity2.roles, inputData);
                }
            }
        }
        this.drawOrganizationalChart(inputData);
    };
    EntityReportComponent.prototype.drawOrganizationalChart = function (inputData) {
        this.chart2 = new Chart({
            chart: {
                inverted: true,
                type: 'organization',
            },
            plotOptions: {
                organization: {
                    dataLabels: {
                        enabled: true
                    },
                }
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            series: [{
                    type: undefined,
                    keys: ['from', 'to', 'name', 'color'],
                    label: {
                        enabled: true
                    },
                    data: inputData,
                    colorByPoint: false,
                    color: '#007ad0',
                    dataLabels: {
                        enabled: true,
                        style: {
                            color: 'white',
                            fontSize: '8px'
                        }
                    },
                    borderColor: 'white',
                    nodeWidth: 65
                }]
        });
    };
    EntityReportComponent.prototype.export = function () {
        var _this = this;
        var node = document.getElementById('newChart');
        htmlToImage.toPng(node).then(function (dataUrl) {
            if (_this.uboChartString) {
                _this.uboChartEncodedImage = dataUrl.split('data:image/png;base64,')[1];
            }
            else {
                _this.uboChartEncodedImage = null;
            }
            if (_this.entityType === 'LEGAL') {
                _this.relationshipGraphString = null;
            }
            if (_this.relationResponse.data.roles.length > 0) {
                svg.svgAsPngUri(document.getElementsByClassName('highcharts-root')[0], {}, function (uri) {
                    if (_this.entityType !== 'LEGAL') {
                        _this.relationshipGraphString = uri.split('data:image/png;base64,')[1];
                    }
                    _this.kybService.getPdfReport2(_this.entityId, localStorage.getItem('username').toUpperCase(), _this.relationshipGraphString, _this.controlChartString, null, _this.uboChartEncodedImage).subscribe(function (data) {
                        _this.pdfResponse = data;
                        var binaryImg = atob(_this.pdfResponse.data.reportData);
                        var length = binaryImg.length;
                        var arrayBuffer = new ArrayBuffer(length);
                        var uintArray = new Uint8Array(arrayBuffer);
                        for (var i = 0; i < length; i++) {
                            uintArray[i] = binaryImg.charCodeAt(i);
                        }
                        var file = new Blob([uintArray], { type: 'application/pdf' });
                        FileSaver.saveAs(file, 'entity-risk-report-' + _this.pdfResponse.data.reportName + '.pdf');
                        _this.loading2 = false;
                        _this.entityId = '';
                    }, function (error) {
                        _this.loading2 = false;
                    });
                });
            }
            else {
                _this.kybService.getPdfReport2(_this.entityId, localStorage.getItem('username').toUpperCase(), _this.relationshipGraphString, _this.controlChartString, null, _this.uboChartEncodedImage).subscribe(function (data) {
                    _this.pdfResponse = data;
                    var binaryImg = atob(_this.pdfResponse.data.reportData);
                    var length = binaryImg.length;
                    var arrayBuffer = new ArrayBuffer(length);
                    var uintArray = new Uint8Array(arrayBuffer);
                    for (var i = 0; i < length; i++) {
                        uintArray[i] = binaryImg.charCodeAt(i);
                    }
                    var file = new Blob([uintArray], { type: 'application/pdf' });
                    FileSaver.saveAs(file, 'entity-risk-report-' + _this.pdfResponse.data.reportName + '.pdf');
                    _this.loading2 = false;
                    _this.entityId = '';
                }, function (error) {
                    _this.loading2 = false;
                });
            }
        });
    };
    return EntityReportComponent;
}());
export { EntityReportComponent };
